import React, { useEffect, useState } from 'react';
import InvoiceCard from '../../components/InvoiceCard'
import {
    Box,
    Button,
    Container,
    CircularProgress,
    Grid2,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    useMediaQuery,
    Typography,
    Breadcrumbs,
    Pagination,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import { getInvoices } from "../../services/invoiceService";
import { useTheme } from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomLink from "../../components/CustomLink";
import InvoiceStatus from "../../components/InvoiceStatus";
import {deleteInvoice} from "../../services/invoiceService";
import {Helmet} from "react-helmet";

const InvoiceTable = () => {

    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('currentPage') || 1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [invoiceStatus, setInvoiceStatus] = useState(searchParams.get('invoiceStatus') || 'open');
    const [invoiceNumber, setInvoiceNumber] = useState(searchParams.get('invoiceNumber') || '');
    const [startDate, setStartDate] = useState(searchParams.get('startDate') !== '' ? searchParams.get('startDate') : null);
    const [endDate, setEndDate] = useState(searchParams.get('endDate') !== '' ? searchParams.get('endDate')  : null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchInvoices = async () => {
        try {
            setLoading(true);
            const response = await getInvoices({
                params: {
                    currentPage: currentPage,
                    perPage: 25,
                    invoiceStatus: invoiceStatus,
                    invoiceNumber: invoiceNumber,
                    startDate: startDate,
                    endDate: endDate
                }
            });
            setInvoices(response.data.data || []);
            setTotalPages(response.data.pagination.totalPages);
            setTotalItems(response.data.pagination.total);
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchInvoices();
    }, [currentPage]);

    const handlePageChange = (event, newPage) => {
        navigate(`?currentPage=${newPage}`);
        setCurrentPage(newPage);
    };


    const handleFilterSubmit = () => {
        const query = {
            invoiceNumber: invoiceNumber,
            invoiceStatus: invoiceStatus,
            startDate: startDate || '',
            endDate: endDate || '',
        };
        // Update the URL search parameters or any further actions
        setSearchParams(query);
        setCurrentPage(1);
        fetchInvoices();
    };

    const handleDateChange = (name, newValue) => {
        if (name === 'startDate') {
            setStartDate(newValue ? newValue.format('DD-MM-YYYY') : null);
        }
        if (name === 'endDate') {
            setEndDate(newValue ? newValue.format('DD-MM-YYYY') : null);
        }
    };
    const cleanFilters = () => {
        const query = {
            invoiceNumber: '',
            invoiceStatus: 'open',
            startDate: '',
            endDate: ''
        }
        setSearchParams(query);
        setInvoiceNumber('');
        setInvoiceStatus('open');
        setStartDate(null);
        setEndDate(null);
        setCurrentPage(1);
        fetchInvoices();
    }

    const handleMenuClick = (event, invoice) => {
        setAnchorEl(event.currentTarget);
        setSelectedInvoice(invoice);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmDelete = () => {
        setOpenDeleteConfirm(true);
    };

    const handleDelete = (uuid) => {
        setIsDeleting(true);
        setSelectedInvoice(null);
        setInvoices(invoices.filter(invoice => invoice.uuid !== selectedInvoice.uuid));
        deleteInvoice(selectedInvoice.uuid).then(
            () => {
                setOpenDeleteConfirm(false);
                setSnackbarOpen(true);
            }
        ).catch((error) => {
            console.error('Error deleting invoice:', error);
            alert('Failed to delete invoice: ' + (error.response?.data?.message || 'Something went wrong.'));
        })
        .finally(() => {
            setIsDeleting(false);
            setOpenDeleteConfirm(false);
        });
    };

    return (
        <Container maxWidth="lg">
            <Helmet>
                <title>
                    Hóa Đơn - Ứng dụng quản lý hoá đơn
                </title>
            </Helmet>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                    <CustomLink
                        href="/app"
                        color="inherit"
                        onClick={() => navigate('/app')}
                        sx={{display: 'flex', alignItems: 'center'}}
                    >
                        <HomeIcon sx={{mr: 0.5}}/>
                    </CustomLink>
                    <Typography color="text.primary">
                        Hóa Đơn
                    </Typography>
                </Breadcrumbs>
                <Button 
                variant="contained"
                onClick={() => navigate('/app/invoices/bulk')}
                color="primary" startIcon={<AddIcon/>}>Thêm hàng loạt</Button>
            </Box>

            {/* Filter Section */}
            <form onSubmit={(e) => {
                e.preventDefault();
                handleFilterSubmit();
            }}>
                <Grid2 container spacing={2}>
                    <Grid2 item xs={12}>
                        <Select
                            fullWidth
                            sx={{minWidth: 250,  height: '56px'}}
                            labelId="invoice-status-label"
                            name="invoiceStatus"
                            value={invoiceStatus}
                            onChange={(e) => setInvoiceStatus(e.target.value)}
                            variant="outlined"
                            size="small"
                        >
                            <MenuItem value="open">Chưa hoàn thành</MenuItem>
                            <MenuItem value="pending">Chờ xử lý</MenuItem>
                            <MenuItem value="delivered">Đã Giao</MenuItem>
                            <MenuItem value="preorder">Đặt trước</MenuItem>
                            <MenuItem value="paid_undelivered">Đã thanh toán và chưa giao</MenuItem>
                            <MenuItem value="paid">Đã hoàn thành</MenuItem>
                            <MenuItem value="all">Tất cả</MenuItem>
                        </Select>
                    </Grid2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ maxWidth: 150 }}
                            label="Từ Ngày"
                            format="DD-MM-YYYY"
                            value={startDate ? dayjs(startDate, 'DD-MM-YYYY') : undefined}
                            onChange={(newValue) => handleDateChange('startDate', newValue)}
                            renderInput={(params) => <TextField {...params} size="small"/>}
                        />
                        <DatePicker
                            sx={{ maxWidth: 150 }}
                            label="Đến Ngày"
                            format="DD-MM-YYYY"
                            value={endDate ? dayjs(endDate, 'DD-MM-YYYY') : undefined}
                            onChange={(newValue) => handleDateChange('endDate', newValue)}
                            renderInput={(params) => <TextField {...params} size="small"/>}
                        />
                    </LocalizationProvider>
                    <TextField
                        sx={{ maxWidth: 150 }}
                        label="Số hóa đơn"
                        name="invoiceNumber"
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                        variant="outlined"
                        size="medium"
                    />
                    <Grid2 item xs={6}>
                        <Button type="submit" variant="contained" color="primary">Lọc</Button>
                    </Grid2>
                    <Grid2 item xs={6}>
                        <Button variant="text"
                                color="info"
                                onClick={cleanFilters}
                        >
                            Xóa bộ lọc
                        </Button>
                    </Grid2>
                </Grid2>
            </form>

            {loading ? (
                <Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                        <CircularProgress/>
                    </Box>
                </Typography>
            ) : (
                isSmallScreen ? (
                    <Box>
                        {invoices.map((invoice, index) => (
                            <InvoiceCard 
                                invoice={invoice}
                            />
                        ))}
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Khách hàng</TableCell>
                                <TableCell>Số hóa đơn</TableCell>
                                <TableCell>Nơi bán</TableCell>
                                <TableCell>Địa chỉ</TableCell>
                                <TableCell>Nội dung</TableCell>
                                <TableCell>Tổng tiền</TableCell>
                                <TableCell>Trạng thái</TableCell>
                                <TableCell>Ngày hóa đơn</TableCell>
                                <TableCell>Thao tác</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices.map((invoice, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography fontWeight="bold">
                                            <CustomLink href={`/app/customers/${invoice.customer.uuid}/view`}>
                                                {invoice.customer.name}
                                            </CustomLink>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <CustomLink href={`/app/invoices/${invoice.uuid}/view`}>
                                            {invoice.invoiceNumber}
                                        </CustomLink>
                                    </TableCell>
                                    <TableCell>
                                        {invoice.communityName}
                                    </TableCell>
                                    <TableCell>{invoice.customer.address}</TableCell>
                                    <TableCell>
                                        <div dangerouslySetInnerHTML={{__html: invoice.note}}/>
                                    </TableCell>
                                    <TableCell>{new Intl.NumberFormat('vi-VN', {
                                        style: 'currency',
                                        currency: 'VND'
                                    }).format(invoice.totalAmount)}</TableCell>
                                    <TableCell>
                                        <InvoiceStatus invoice={invoice}/>
                                    </TableCell>
                                   <TableCell>{dayjs(invoice.invoiceDate).format('DD-MM-YYYY')}</TableCell>
                                   <TableCell>
                                        <IconButton onClick={(e) => handleMenuClick(e, invoice)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedInvoice?.uuid === invoice.uuid}
                                            onClose={handleMenuClose}
                                            PaperProps={{ style: { width: 200 } }}
                                        >
                                            <MenuItem
                                                component="a"
                                                onClick={() => navigate(`/app/invoices/${invoice.uuid}`)}
                                            >Sửa</MenuItem>
                                            <MenuItem onClick={handleConfirmDelete}>Xóa</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>                  
                )
            )}
            {totalPages > 1 && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                    />
                </Box>
            )}
            <Dialog
                open={openDeleteConfirm}
                onClose={() => setOpenDeleteConfirm(false)}
            >
                <DialogTitle>Xác Nhận xóa</DialogTitle>
                <DialogContent>
                    <Typography>
                        Bạn có chắn muốn xóa hóa đơn này không?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
                        Hủy bỏ
                    </Button>
                    <Button onClick={handleDelete} color="error" disabled={isDeleting}>
                        {isDeleting ? <CircularProgress /> : 'Xóa'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={10000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Hóa đơn đã được xóa</Alert>
            </Snackbar>
        </Container>
    );
};

export default InvoiceTable;
