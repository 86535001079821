import React from 'react';
import AddInvoiceForm from './AddInvoiceForm';

const EditInvoiceForm = () => {
    return (
        <AddInvoiceForm isEditMode={true} />
    );
};

export default EditInvoiceForm;
