import api from './apiService';

export const getCustomer = (keyword) => {
    return api.get('/customers', {
        params: {
            keyword,
        },
    });
};

export const deleteCustomer = (uuid) => {
    return api.delete(`/customers/${uuid}`);
};

export const getOneCustomer = (uuid) => {
    return api.get(`/customers/${uuid}`);
};

export const updateCustomer = (uuid, params) => {
    return api.put(`/customers/${uuid}`, {
        ...params
    });
};

export const saveCustomer = (params) => {
    return api.post(`/customers`, {
        ...params
    });
};

