import React from 'react';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomLink = ({ href, children, sx, ...props }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        navigate(href); // Use React Router to navigate without reloading
    };

    return (
        <Link
            href={href}
            onClick={handleClick}
            sx={{ cursor: 'pointer', ...sx }}
            {...props} // Spread any additional props to customize this link
        >
            {children}
        </Link>
    );
};

export default CustomLink;
