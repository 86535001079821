import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Snackbar,
    Alert,
    Breadcrumbs,
    Link,
    Tooltip,
    useMediaQuery,
    Box,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Container,
    IconButton,
    Pagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HomeIcon from "@mui/icons-material/Home";
import { getOneCustomer, deleteCustomer } from "../../services/customerService";
import CustomLink from "../../components/CustomLink";
import InvoiceCard from "../../components/InvoiceCard";
import InvoiceStatus from "../../components/InvoiceStatus";
import dayjs from "dayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {getInvoices} from "../../services/invoiceService";
import { useSearchParams } from 'react-router-dom';
import {Helmet} from "react-helmet";

const CustomerView = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(searchParams.get('currentPage') || 1);
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [customer, setCustomer] = useState([]);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setLoading(true);
        getOneCustomer(id).then(response => {
            setCustomer(response.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchInvoices();
    }, [currentPage]);

    const fetchInvoices = async () => {
        try {
            setIsInvoiceLoading(true);
            const response = await getInvoices({
                params: {
                    currentPage: currentPage,
                    perPage: 25,
                    invoiceStatus: 'all',
                    customerUuid: id
                }
            });
            setInvoices(response.data.data || []);
            setTotalPages(response.data.pagination.totalPages);
            setTotalItems(response.data.pagination.total);
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
        }
        setIsInvoiceLoading(false);
    };

    const handlePageChange = (event, newPage) => {
        navigate(`?currentPage=${newPage}`);
        setCurrentPage(newPage);
    };

    const handleEdit = () => {
        navigate(`/app/customers/${id}`);
    }

    const handleConfirmDelete = () => {
        setOpenDeleteConfirm(true);
    }

    const handleDelete = async () => {
        try {
            await deleteCustomer(id);
            navigate('/app/customers');
        } catch (error) {
            console.error(error);
        }
    }


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>Xem Khách hàng</title>
            </Helmet>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <CustomLink
                        underline="hover"
                        color="inherit"
                        href='/app'
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} />
                    </CustomLink>
                    <CustomLink
                        underline="hover"
                        color="inherit"
                        href='/app/customers'
                    >
                        Khách hàng
                    </CustomLink>
                    <CustomLink
                        underline="hover"
                        color="inherit"
                        href={`/app/customers/${id}/view`}
                    >
                        {customer?.name}
                    </CustomLink>
                    <Typography color="text.primary">
                        Xem hóa đơn
                    </Typography>
                </Breadcrumbs>
                <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
                    <Button variant="contained" color="primary" onClick={handleEdit}>
                        Sửa
                    </Button>
                    <Button variant="contained" color="error" onClick={handleConfirmDelete}>
                        Xóa
                    </Button>
                </Box>
            </Box>
            <Typography variant="h4" component="h1">Chi tiết khách hàng</Typography>
            <TableContainer component={Paper}>
                <Table aria-label="key-value table">
                    <TableBody>
                        <TableRow key='kh'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Khách Hàng
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {customer?.name}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='nb'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Nơi bán:
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {customer?.communityName}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='sdt'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Số điện thoại
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {customer?.phone}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='dc'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Địa chỉ
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {customer?.address}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={4}>
                <Typography variant="h4" component="h1">Danh sách đơn hàng</Typography>
            </Box>

            {isInvoiceLoading ? (
                <Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                        <CircularProgress/>
                    </Box>
                </Typography>
            ) : (
                isSmallScreen ? (
                    <Box>
                        {invoices.map((invoice, index) => (
                            <InvoiceCard
                                invoice={invoice}
                            />
                        ))}
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Số hóa đơn</TableCell>
                                    <TableCell>Nội dung</TableCell>
                                    <TableCell>Tổng tiền</TableCell>
                                    <TableCell>Trạng thái</TableCell>
                                    <TableCell>Ngày hóa đơn</TableCell>
                                    <TableCell>Thao tác</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((invoice, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <CustomLink href={`/app/invoices/${invoice.uuid}/view`}>
                                                {invoice.invoiceNumber}
                                            </CustomLink>
                                        </TableCell>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{__html: invoice.note}}/>
                                        </TableCell>
                                        <TableCell>{new Intl.NumberFormat('vi-VN', {
                                            style: 'currency',
                                            currency: 'VND'
                                        }).format(invoice.totalAmount)}</TableCell>
                                        <TableCell>
                                            <InvoiceStatus invoice={invoice}/>
                                        </TableCell>
                                        <TableCell>{dayjs(invoice.invoiceDate).format('DD-MM-YYYY')}</TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <MoreVertIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
            {totalPages > 1 && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                    />
                </Box>
            )}

            <Dialog
                open={openDeleteConfirm}
                onClose={() => setOpenDeleteConfirm(false)}
            >
                <DialogTitle>Xác Nhận xóa</DialogTitle>
                <DialogContent>
                    <Typography>
                        Bạn có chắn muốn xóa hóa đơn này không?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
                        Hủy bỏ
                    </Button>
                    <Button onClick={handleDelete} color="error" disabled={isDeleting}>
                        {isDeleting ? <CircularProgress /> : 'Xóa'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default CustomerView;
