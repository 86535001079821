import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';

const Signup = () => {
    const { signup } = useContext(AuthContext);
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ email: '', password: '', remember: false });

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setCredentials((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        signup(credentials, () => navigate('/dashboard'));
    };

    return (
        <Container maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
                <Typography variant="h4" gutterBottom>Signup</Typography>
                <Box component="form" onSubmit={handleSubmit} width="100%">
                    <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Password"
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        required
                    />
                    <FormControlLabel
                        control={<Checkbox name="remember" onChange={handleChange} />}
                        label="Remember Password"
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        Signup
                    </Button>
                    <Button
                        component={Link}
                        to="/login"
                        color="secondary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Already have an account? Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Signup;
