import React, {useEffect, useState} from 'react';
import {
    Container,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    IconButton,
    Link,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {getOneCustomer, updateCustomer, saveCustomer} from "../../services/customerService";
import { joinedCommunity } from "../../services/communityService";
import {Helmet} from "react-helmet";

const AddCustomerForm = ({isEditMode = false}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [customerName, setCustomerName] = useState('');
    const [community, setCommunity] = useState('other');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customerNameError, setCustomerNameError] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [apiError, setApiError] = useState('');

    useEffect(() => {
        const cachedLocations = localStorage.getItem('locations');
        const cacheTimestamp = localStorage.getItem('locations_timestamp');
        const oneHour = 60 * 60 * 1000;

        if (cachedLocations && cacheTimestamp && (Date.now() - cacheTimestamp < oneHour)) {
            setLocations(JSON.parse(cachedLocations));
            setLoading(false);
        } else {
            setLoading(true);
            joinedCommunity()
                .then(response => {
                    setLocations(response.data);
                    localStorage.setItem('locations', JSON.stringify(response.data));
                    localStorage.setItem('locations_timestamp', Date.now().toString());
                })
                .catch(error => {
                    console.error('Error fetching sales locations:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        if (isEditMode && id) {
            setLoading(true);
            getOneCustomer(id)
                .then(response => {
                    const customer = response.data;
                    setCustomerName(customer.name);
                    setCommunity(customer.communityId);
                    setAddress(customer.address);
                    setPhoneNumber(customer.phone);
                })
                .catch(error => {
                    console.error('Error fetching customer details:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [isEditMode, id]);

    const handleSave = () => {
        if (!customerName.trim()) {
            setCustomerNameError(true);
            return;
        }
        setFormLoading(true);

        const params = {
            name: customerName,
            community: community,
            address: address,
            phone: phoneNumber
        };
        const apiCall = isEditMode ? updateCustomer(id,params) : saveCustomer(params);
        apiCall
            .then(response => {
                console.log('Customer saved successfully:', response.data);
                navigate('/app/customers');
            })
            .catch(error => {
                if (error.response && error.response.status >= 400 && error.response.status < 500) {
                    setApiError(error.response.data.error || 'An error occurred while saving the customer.');
                } else {
                    console.error('Error saving customer:', error);
                }
            })
            .finally(() => {
                setFormLoading(false);
            });
    };

    const handleCustomerNameBlur = () => {
        if (!customerName.trim()) {
            setCustomerNameError(true);
        } else {
            setCustomerNameError(false);
        }
    };

    if (loading) {
        return (<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <CircularProgress/>
            </Box>);
    }

    return (
        <Container>
            <Helmet>
                <title>
                    {isEditMode ? 'Chỉnh sửa khách hàng' : 'Thêm khách hàng'} | Quản lý khách hàng
                </title>
            </Helmet>
            <Box sx={{width: '100%', margin: '0 auto', padding: 2}}>
                <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app')}
                        sx={{display: 'flex', alignItems: 'center'}}
                    >
                        <HomeIcon sx={{mr: 0.5}}/>
                    </Link>
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app/customers')}>
                        Khách hàng
                    </Link>
                    <Typography color="text.primary">
                        {isEditMode ? 'Chỉnh sửa khách hàng' : 'Thêm khách hàng'}
                    </Typography>
                </Breadcrumbs>

                <Typography variant="h4" component="h1" gutterBottom>
                    {isEditMode ? 'Chỉnh sửa khách hàng' : 'Thêm khách hàng'}
                </Typography>

                {apiError && (<Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                    <Typography color="error" sx={{flexGrow: 1}}>
                        {apiError}
                        <IconButton onClick={() => setApiError('')} color="secondary" sx={{marginRight: 1}}>
                            <CloseIcon/>
                        </IconButton>
                    </Typography>
                </Box>)}

                <TextField
                    required
                    label="Tên khách hàng"
                    fullWidth
                    margin="normal"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    onBlur={handleCustomerNameBlur}
                    error={customerNameError}
                    helperText={customerNameError ? 'Tên khách hàng là bắt buộc.' : ''}
                />

                <TextField
                    select
                    label="Nơi bán"
                    fullWidth
                    margin="normal"
                    value={community}
                    onChange={(e) => setCommunity(e.target.value)}
                >
                    <MenuItem value="other">Khác</MenuItem>
                    {locations.map((location) => (<MenuItem key={location.id} value={location.id}>
                            {location.name}
                        </MenuItem>))}
                </TextField>

                <TextField
                    label="Mã căn hộ/Địa chỉ"
                    fullWidth
                    margin="normal"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />

                <TextField
                    label="Số điện thoại"
                    fullWidth
                    margin="normal"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{marginTop: 2}}
                    disabled={formLoading}
                >
                    {formLoading ? <CircularProgress size={24}/> : 'Lưu'}
                </Button>
            </Box>
        </Container>
    );
};

export default AddCustomerForm;