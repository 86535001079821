import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {userLogin} from "../services/authService";
import {getUserDetail} from "../services/userService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [user, setUser] = useState(null); // Store user details here
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUserDetails(); // Fetch user details if token exists
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    const fetchUserDetails = async () => {
        try {
            const response = await getUserDetail();
            setUser(response.data); // Store user details
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
                logout(); // Clear cookie and redirect to login on 4xx errors
            }
        }
    };

    const login = async ({ email, password, remember }, callback, setError) => {
        try {
            const response = await userLogin({ email, password });
            const token = response.data.token;
            const refreshToken = response.data.refresh_token || null;

            if (remember) {
                //store to local storage
                localStorage.setItem('refresh_token', refreshToken);
            }
            localStorage.setItem('token', token);

            setIsAuthenticated(true);
            await fetchUserDetails(); // Fetch user details on successful login
            if (callback) callback();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        setIsAuthenticated(false);
        setUser(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, signup: login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
