import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    IconButton,
    Typography,
    Box,
    Breadcrumbs,
    Autocomplete,
    Link,
    CircularProgress,
    Container,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Stack,
    useMediaQuery,
    FormLabel,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { Add, Delete, Label } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {getCustomer, getOneCustomer} from "../../services/customerService";
import HomeIcon from "@mui/icons-material/Home";
import {getProducts, createInvoice, updateInvoice, getSingleInvoice} from "../../services/invoiceService";
import {Helmet} from "react-helmet";

const AddInvoiceForm = ({isEditMode = false}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [customer, setCustomer] = useState(null);
    const [isCustomerLoading, setIsCustomerLoading] = useState(true);
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [products, setProducts] = useState([]);
    const [invoiceProducts, setInvoiceProducts] = useState([createEmptyProduct()]);
    const [shippingFee, setShippingFee] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [note, setNote] = useState('');
    const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
    const [customerUuid, setCustomerUuid] = useState(searchParams.get('customerUuid') || null);
    const isSmallScreen = useMediaQuery('(max-width:600px)');


    useEffect(() => {
        setIsProductLoading(true);
        getProducts()
            .then(response => {
                setProducts(response.data);
            })
            .finally(() => {
                setIsProductLoading(false);
            });
    }, []);

    useEffect(() => {
        calculateTotalAmount();
    }, [invoiceProducts, shippingFee, discount]);

    useEffect(() => {
        setIsCustomerLoading(true);
        if (isEditMode && id) {
            getSingleInvoice(id)
                .then(response => {
                    let date = response.data.invoiceDate.split('T')[0];
                    setInvoiceDate(date);
                    setShippingFee(response.data.shippingFee);
                    setDiscount(response.data.discount || 0);
                    setTotalAmount(response.data.totalAmount);
                    setNote(response.data.note);
                    setCustomerUuid(response.data.customer.uuid);
                    console.log(response.data.invoicesItems);
                    setInvoiceProducts(response.data.invoicesItems.map(invoiceItem => ({
                        id: invoiceItem.productUuid,
                        name: invoiceItem.itemName,
                        quantity: invoiceItem.quantity,
                        unit: invoiceItem.unitName,
                        price: invoiceItem.originalPrice,
                        total: invoiceItem.finalPrice
                    })));
                }).catch(() => {
                    navigate('/app/invoices');
                });
        }
    }, [isEditMode, id]);

    useEffect(() => {
        if (!customerUuid) {
            return;
        }
        getOneCustomer(customerUuid)
            .then(response => {
                setCustomer(response.data);
            })
            .catch(() => {
                if (!isEditMode) {
                    navigate('/app/invoices');
                }
                setIsCustomerLoading(false);
            })
            .finally(() => {
                setIsCustomerLoading(false);
            });
    }, [customerUuid]);

    function createEmptyProduct() {
        return { id: null, name: '', quantity: 1, unit: '', price: 0, total: 0 };
    }

    const handleProductChange = (index, value) => {
        const updatedProducts = [...invoiceProducts];
        const selectedProduct = updatedProducts[index];

        if (value) {
            const product = products.find((p) => p.uuid === value.id);
            if (product) {
                selectedProduct.id = product.uuid;
                selectedProduct.name = product.name;
                selectedProduct.unit = product.unit;
                selectedProduct.price = product.price;
                selectedProduct.total = parseInt(product.price * selectedProduct.quantity);
            }
        } else {
            selectedProduct.id = null;
            selectedProduct.name = '';
            selectedProduct.unit = '';
            selectedProduct.price = 0;
            selectedProduct.total = 0;
        }
        updatedProducts[index] = selectedProduct;
        setInvoiceProducts(updatedProducts);
    };

    const handleManualProductChange = (index, manualName = '') => {
        const updatedProducts = [...invoiceProducts];
        const selectedProduct = updatedProducts[index];
        selectedProduct.name = manualName;
        updatedProducts[index] = selectedProduct;
        setInvoiceProducts(updatedProducts);
    };

    const handleFieldChange = (index, field, value) => {
        const updatedProducts = [...invoiceProducts];
        const selectedProduct = updatedProducts[index];
        if (field === 'quantity' || field === 'price') {
            if (value === null || isNaN(value)) {
                value = '';
            }
            selectedProduct[field] = value;
            selectedProduct.total = parseInt(selectedProduct.price * selectedProduct.quantity);
        } else {
            selectedProduct[field] = value;
        }

        updatedProducts[index] = selectedProduct;
        setInvoiceProducts(updatedProducts);
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;
        invoiceProducts.forEach((product) => {
            totalAmount += product.total;
        });
        totalAmount += shippingFee - discount;
        setTotalAmount(totalAmount);
    };

    const handleAddProduct = () => {
        setInvoiceProducts([...invoiceProducts, createEmptyProduct()]);
    };

    const handleRemoveProduct = (index) => {
        const updatedProducts = invoiceProducts.filter((_, i) => i !== index);
        setInvoiceProducts(updatedProducts);
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        const invoiceData = {
            customerUuid,
            invoiceDate,
            products: invoiceProducts.map(product => ({
                id: product.id,
                name: product.name,
                quantity: product.quantity,
                unit: product.unit,
                price: product.price,
                total: product.total
            })),
            shippingFee,
            discount,
            totalAmount,
            note,
        };
        if (isEditMode) {
            updateInvoice(id, invoiceData)
                .then(response => {
                    if (response.status === 200) {
                        navigate(`/app/invoices/${response.data.uuid}/view`);
                    }
                })
                .catch(error => {
                    console.error('Error creating invoice:', error);
                    setErrorMessage('Có lỗi xảy ra khi cập nhật đơn hàng. Vui lòng thử lại sau.');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } else {
            createInvoice(invoiceData)
                .then(response => {
                    if (response.status === 201) {
                        navigate(`/app/invoices/${response.data.uuid}/view`);
                    }
                })
                .catch(error => {
                    console.error('Error creating invoice:', error);
                    setErrorMessage('Có lỗi xảy ra khi tạo đơn hàng. Vui lòng thử lại sau.');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
        
    };

    if (isCustomerLoading || isProductLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>
                    {isEditMode ? 'Sửa Đơn Hàng' : 'Thêm Đơn Hàng'} | Ứng dụng quản lý hoá đơn
                </title>
            </Helmet>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <Link color="inherit" href="/">
                        <HomeIcon sx={{ mr: 0.5 }} />
                    </Link>
                    <Link color="inherit" href={`/app/customers`}>
                        <Typography>Khách hàng</Typography>
                    </Link>
                    {customer && customer.name && (
                        <Link color="inherit" href={`/app/customers/${id}/view`}>
                            <Typography>{customer.name}</Typography>
                        </Link>
                    )}
                    <Typography color="text.primary">{isEditMode ? "Sửa Đơn Hàng" : "Thêm Đơn Hàng"}</Typography>
                </Breadcrumbs>
            </Box>
            {customer && <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="customer-info-content"
                    id="customer-info-header"
                >
                    <Typography variant="h6">Khách Hàng: <strong>{customer?.name}</strong></Typography>
                </AccordionSummary>
                <AccordionDetails>
                <TableContainer>
                    <Table aria-label="key-value table">
                        <TableBody>
                            <TableRow key='kh'>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Khách Hàng
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">
                                        {customer?.name}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key='nb'>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Nơi bán:
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">
                                        {customer?.communityName}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key='sdt'>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Số điện thoại
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">
                                        {customer?.phone}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key='dc'>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Địa chỉ
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">
                                        {customer?.address}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </AccordionDetails>
            </Accordion>
            }
            <Stack spacing={2} marginTop={2}>
                {errorMessage && <Alert severity="error" action={
                                        <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setErrorMessage('')}
                                        >
                                        <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }>
                                        {errorMessage}
                                </Alert>}
                <TextField
                    label="Ngày đặt hàng"
                    type="date"
                    value={invoiceDate}
                    fullWidth
                    onChange={(e) => setInvoiceDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <FormLabel>Danh sách sản phẩm</FormLabel>
                {invoiceProducts.map((product, index) => (
                    <Stack
                    direction={isSmallScreen ? "column" : "row"}
                    spacing={2}
                    key={index}
                    alignItems="center"
                    sx={{ 
                        width: "100%" ,
                        border: "1px solid #ccc", // Light gray border like a card
                        borderRadius: "8px", // Rounded corners
                        padding: "16px", // Padding for inner spacing
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for a card effect
                        backgroundColor: "#F9F9F9", // White background
                    }}
                  >
                    <Autocomplete
                      id="products"
                      freeSolo
                      value={{ label: product.name, id: product.id }}
                      onChange={(e, value) => handleProductChange(index, value)}
                      options={products.map((option) => ({
                        label: option.name,
                        id: option.uuid,
                      }))}
                      fullWidth={isSmallScreen}
                      sx={{
                        ...(isSmallScreen ? {} : { width: 400 }),
                      }}
                      renderInput={(params) => <TextField {...params} 
                            label="Chọn sản phẩm"
                            onBlur={(e) => handleManualProductChange(index, e.target.value)}
                        />}
                    />
                  
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ 
                            ...(isSmallScreen ? { 
                                flexDirection: "row",
                                width: "100%"
                             } : {
                                width: 200
                             }) 
                        }}
                    >
                      <TextField
                        type="number"
                        label="Số lượng"
                        value={product.quantity}
                        inputProps={{ step: 0.1 }}
                        fullWidth
                        onChange={(e) => handleFieldChange(index, 'quantity', parseFloat(e.target.value))}
                      />
                      <TextField
                        label="Đơn vị tính"
                        value={product.unit}
                        disabled={!!product.id}
                        fullWidth
                        onChange={(e) => handleFieldChange(index, 'unit', e.target.value)}
                      />
                    </Stack>
                  
                    <TextField
                      label="Đơn giá"
                      type="number"
                      value={product.price}
                      inputProps={{ step: 1 }}
                      fullWidth={isSmallScreen}
                      onChange={(e) => handleFieldChange(index, 'price', parseFloat(e.target.value))}
                    />
                    <TextField
                      label="Tổng giá"
                      value={product.total}
                      fullWidth={isSmallScreen}
                      disabled
                    />
                    <IconButton onClick={() => handleRemoveProduct(index)}>
                      <Delete color="error" />
                    </IconButton>
                  </Stack>
                ))}
                <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={handleAddProduct}
                    sx={{ maxWidth: 200 }}
                    size="small"
                >
                    Thêm Sản phẩm
                </Button>
                <TextField
                    label="Phí giao hàng"
                    type="number"
                    value={shippingFee}
                    fullWidth
                    onChange={(e) => setShippingFee(parseFloat(e.target.value))}
                />
                <TextField
                    label="Giảm giá"
                    type="number"
                    value={discount}
                    fullWidth
                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                />
                <TextField
                    label="Tổng cộng"
                    type="number"
                    value={totalAmount}
                    fullWidth
                    onChange={(e) => setTotalAmount(parseFloat(e.target.value))}
                />
                <TextField
                    label="Ghi chú"
                    value={note}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => setNote(e.target.value)}
                />
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSubmit} 
                        disabled={isSubmitting}
                        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                    >
                        {isEditMode ? 
                            (isSubmitting ? 'Đang cập nhật...' : 'Cập nhật đơn hàng')
                            : (isSubmitting ? 'Đang tạo...' : 'Tạo đơn hàng')
                        }
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
};

export default AddInvoiceForm;
