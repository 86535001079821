import axios from 'axios';
import {API_URLS} from "../constants";

// Create an instance of axios
const api = axios.create({
    baseURL: API_URLS.BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const getDashboard = (period) => {
    return api.get('/dashboard', {
        params: {
            period: period
        }
    });
};