import api from './apiService';

export const getInvoices = (params) => {
    return api.get('/invoices', {
        ...params
    });
};

export const getSingleInvoice = (uuid) => {
    return api.get('/invoices/' + uuid);
};
export const deleteInvoice = (uuid) => {
    return api.delete('/invoices/' + uuid);
};
export const changeInvoiceStatus = (uuid, status) => {
    return api.post('/invoices/' + uuid + '/change-status', {
        status: status
    });
};

export const createInvoice = (data) => {
    return api.post('/invoices', data);
}

export const updateInvoice = (uuid, data) => {
    return api.put('/invoices/' + uuid, data);
}

export const getProducts = () => {
    return api.get('/products');
};

export const bulkInvoices = (data) => {
    return api.post('/invoices/bulk', data);
};