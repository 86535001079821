import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Snackbar,
    Alert,
    Breadcrumbs, Link, Fab, Tooltip
} from '@mui/material';
import {
    Container,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
    Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import HomeIcon from "@mui/icons-material/Home";
import { getCustomer, deleteCustomer } from "../../services/customerService";
import CustomLink from "../../components/CustomLink";
import {Helmet} from "react-helmet";

const CustomerList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [deleteLoading, setDeleteLoading] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

useEffect(() => {
    const handler = setTimeout(() => {
        fetchCustomers();
    }, 500);

    return () => {
        clearTimeout(handler);
    };
}, [filter]);

    const fetchCustomers = async () => {
        setLoading(true);
        try {
            const response = await getCustomer(filter);
            setCustomers(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching customers:', error);
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleMenuClick = (event, customer) => {
        setAnchorEl(event.currentTarget);
        setSelectedCustomer(customer);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (selectedCustomer) {
            setDeleteLoading(true);
            try {
                const response = await deleteCustomer(selectedCustomer.uuid);
                setCustomers(customers.filter(customer => customer.uuid !== selectedCustomer.uuid));
                setSnackbarMessage(response.data.message);
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setConfirmOpen(false);
                setSelectedCustomer(null); // Reset selected customer after deletion
            } catch (error) {
                console.error('Error deleting customer:', error);
                setSnackbarMessage(error.response?.data?.error || 'Error deleting customer');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setConfirmOpen(false); // Close dialog even if there's an error
            } finally {
                setDeleteLoading(false);
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Helmet>
                <title>Khách hàng</title>
            </Helmet>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app')}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} />
                    </Link>
                    <Typography color="text.primary">
                        Khách hàng
                    </Typography>
                </Breadcrumbs>
                <Button
                    variant="contained"
                    onClick={() => navigate('/app/customers/add')}
                    color="primary"
                    startIcon={<AddIcon />}
                >
                    Thêm Khách hàng
                </Button>
            </Box>
            <TextField
                label="Tìm khách hàng"
                variant="outlined"
                fullWidth
                margin="normal"
                value={filter}
                onChange={handleFilterChange}
            />
            {loading ? (
                <Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                        <CircularProgress />
                    </Box>
                </Typography>
            ) : customers.length === 0 ? (
                <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
                    No Customer Found
                </Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Tên</TableCell>
                                {!isSmallScreen && <TableCell>Nơi bán</TableCell>}
                                <TableCell>Địa chỉ</TableCell>
                                {!isSmallScreen && <TableCell>Điện thoại</TableCell>}
                                {!isSmallScreen && <TableCell>Ngày tạo</TableCell>}
                                <TableCell>Thao Tác</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers.map((customer) => (
                                <TableRow key={customer.uuid}>
                                    <TableCell>
                                        <CustomLink href={`/app/invoices/add?customerUuid=${customer.uuid}`}>
                                            <Tooltip title="Thêm Đơn Hàng">
                                                <IconButton color="primary" title="Thêm Đơn Hàng">
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CustomLink>
                                    </TableCell>
                                    <TableCell>
                                        <CustomLink href={`/app/customers/${customer.uuid}/view`}>
                                            {customer.name}
                                        </CustomLink>
                                    </TableCell>
                                    {!isSmallScreen && <TableCell>{customer.community}</TableCell>}
                                    <TableCell>{customer.address}</TableCell>
                                    {!isSmallScreen && <TableCell>{customer.phone}</TableCell>}
                                    {!isSmallScreen && <TableCell>{customer.createdAt}</TableCell>}
                                    <TableCell>
                                        <IconButton onClick={(e) => handleMenuClick(e, customer)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedCustomer?.uuid === customer.uuid}
                                            onClose={handleMenuClose}
                                            PaperProps={{ style: { width: 200 } }}
                                        >
                                            <MenuItem
                                                component="a"
                                                onClick={() => navigate(`/app/customers/${customer.uuid}`)}
                                            >Sửa</MenuItem>
                                            <MenuItem onClick={handleDeleteClick}>Xóa</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Dialog
                open={confirmOpen}
                onClose={handleConfirmClose}
            >
                <DialogTitle>Xác nhận xóa khách hàng</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bạn có chắc chắn muốn xóa khách hàng này không?
                    </DialogContentText>
                    {deleteLoading && (
                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                            <CircularProgress />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="primary" disabled={deleteLoading}>
                        Hủy
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary" autoFocus disabled={deleteLoading}>
                        Xóa
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default CustomerList;
