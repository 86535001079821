import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Breadcrumbs,
    Container, DialogTitle, DialogContent, DialogActions, Dialog
} from '@mui/material';
import {useParams} from "react-router-dom";
import {getSingleInvoice} from "../../services/invoiceService";
import HomeIcon from "@mui/icons-material/Home";
import CustomLink from "../../components/CustomLink";
import {deleteInvoice} from "../../services/invoiceService";
import InvoiceStatus from "../../components/InvoiceStatus";
import {Helmet} from "react-helmet";

const InvoiceView = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [invoiceData, setInvoiceData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        getSingleInvoice(id)
            .then(response => {
                setInvoiceData(response.data);
            })
            .catch(error => {
                navigate('/app/invoices');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleEdit = () => {
        navigate(`/app/invoices/${id}`);
    };

    const handleViewPdf = () => {
        navigate(`/app/invoices/${id}/pdf`);
    };

    const handleConfirmDelete = () => {
        setOpenDeleteConfirm(true);
    };

    const handleDelete = () => {
        setIsDeleting(true);
        deleteInvoice(id).then(
            () => {
                setOpenDeleteConfirm(false);
                navigate('/app/invoices');
            }
        ).catch((error) => {
            console.error('Error deleting invoice:', error);
            alert('Failed to delete invoice: ' + (error.response?.data?.message || 'Something went wrong.'));
        })
        .finally(() => {
            setIsDeleting(false);
            setOpenDeleteConfirm(false);
        });
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>
                    Xem Hóa Đơn
                </title>
            </Helmet>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                    <CustomLink
                        underline="hover"
                        color="inherit"
                        href='/app'
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} />
                    </CustomLink>
                    <CustomLink
                        underline="hover"
                        color="inherit"
                        href='/app/invoices'
                    >
                        Hóa Đơn
                    </CustomLink>
                    <CustomLink
                        underline="hover"
                        color="inherit"
                        href={`/app/customers/${invoiceData.customer.uuid}/view`}
                    >
                        {invoiceData.customer?.name}
                    </CustomLink>
                    <Typography color="text.primary">
                        Xem hóa đơn
                    </Typography>
                </Breadcrumbs>
                <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
                    <Button variant="contained" color="primary" onClick={handleEdit}>
                        Sửa
                    </Button>
                    <Button variant="contained" color="error" onClick={handleConfirmDelete}>
                        Xóa
                    </Button>
                </Box>
            </Box>
            <Typography variant="h4" component="h1">Chi tiết hóa đơn</Typography>
            <TableContainer component={Paper}>
                <Table aria-label="key-value table">
                    <TableBody>
                        <TableRow key='kh'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Khách Hàng
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {invoiceData.customer?.name}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='nb'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Nơi bán:
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {invoiceData.communityName}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='sdt'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Số điện thoại
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {invoiceData.customer?.phone}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='dc'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Địa chỉ
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {invoiceData.customer?.address}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='tt'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Trạng thái
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    <InvoiceStatus invoice={invoiceData} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='nt'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Ngày hóa đơn
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {new Date(invoiceData.invoiceDate).toLocaleDateString('vi-VN')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='lhd'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Link hóa đơn
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    <Button onClick={() => handleViewPdf()}>
                                        Link hóa đơn
                                    </Button>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key='gc'>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Ghi chú
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    <div dangerouslySetInnerHTML={{__html: invoiceData.note}}/>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={4}>
                <Typography variant="h4" component="h1">Sản phẩm</Typography>
            </Box>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>STT</TableCell>
                            <TableCell>Tên</TableCell>
                            <TableCell>Số lượng</TableCell>
                            <TableCell>Đơn vị tính</TableCell>
                            <TableCell>Đơn giá</TableCell>
                            <TableCell>Thành tiền</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {invoiceData.invoicesItems && invoiceData.invoicesItems.map((invoicesItem, index) => (
                                <TableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{invoicesItem.itemName}</TableCell>
                                    <TableCell>{invoicesItem.quantity}</TableCell>
                                    <TableCell>{invoicesItem.unitName}</TableCell>
                                    <TableCell>
                                        {new Intl.NumberFormat('vi-VN', {
                                            style: 'currency',
                                            currency: 'VND'
                                        }).format(invoicesItem.originalPrice)}
                                    </TableCell>
                                    <TableCell>
                                        {new Intl.NumberFormat('vi-VN', {
                                            style: 'currency',
                                            currency: 'VND'
                                        }).format(invoicesItem.finalPrice)}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={5} align="right">
                                    Phí Giao Hàng:
                                </TableCell>
                                <TableCell colSpan={2}>
                                    {new Intl.NumberFormat('vi-VN', {
                                        style: 'currency',
                                        currency: 'VND'
                                    }).format(invoiceData.shippingFee)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5} align="right">
                                    Giảm giá:
                                </TableCell>
                                <TableCell colSpan={2}>
                                    {new Intl.NumberFormat('vi-VN', {
                                        style: 'currency',
                                        currency: 'VND'
                                    }).format(invoiceData.discount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5} align="right">
                                    Tổng cộng:
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <strong>{new Intl.NumberFormat('vi-VN', {
                                        style: 'currency',
                                        currency: 'VND'
                                    }).format(invoiceData.totalAmount)}</strong>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openDeleteConfirm}
                onClose={() => setOpenDeleteConfirm(false)}
            >
                <DialogTitle>Xác Nhận xóa</DialogTitle>
                <DialogContent>
                    <Typography>
                        Bạn có chắn muốn xóa hóa đơn này không?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
                        Hủy bỏ
                    </Button>
                    <Button onClick={handleDelete} color="error" disabled={isDeleting}>
                        {isDeleting ? <CircularProgress /> : 'Xóa'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
export default InvoiceView;
