import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {AuthProvider} from './contexts/AuthContext';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ProtectedRoute from './pages/ProtectedRoute';
import LandingPage from './pages/LandingPage';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import Customers from './pages/Customers/Customers';
import AddCustomerForm from './pages/Customers/AddCustomerForm';
import EditCustomerForm from './pages/Customers/EditCustomerForm';
import Invoices from './pages/Invoices/Invoices';
import Expenses from './pages/Expenses';
import Revenue from './pages/Revenue';
import Products from './pages/Products';
import Imports from './pages/Imports';
import Locations from './pages/Locations';
import InvoiceView from "./pages/Invoices/InvoiceView";
import EditInvoiceForm from "./pages/Invoices/EditInvoiceForm";
import CustomerView from "./pages/Customers/CustomerView";
import AddInvoiceForm from "./pages/Invoices/AddInvoiceForm";
import AddBulkInvoiceForm from "./pages/Invoices/AddBulkInvoiceForm";
import InvoiceViewPdf from "./pages/Invoices/InvoiceViewPdf";

const App = () => {
    return (<Router>
        <AuthProvider>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/signup" element={<Signup/>}/>
                <Route
                    path="/app"
                    element={<ProtectedRoute>
                        <HomePage/>
                    </ProtectedRoute>}
                >
                    <Route path="dashboard" element={<Dashboard/>}/>
                    <Route path="customers" element={<Customers/>}/>
                    <Route path="customers/add" element={<AddCustomerForm/>}/>
                    <Route path="customers/:id" element={<EditCustomerForm/>}/>
                    <Route path="customers/:id/view" element={<CustomerView/>}/>
                    <Route path="/app/invoices/add" element={<AddInvoiceForm/>}/>
                    <Route path="invoices" element={<Invoices/>}/>
                    <Route path="invoices/:id" element={<EditInvoiceForm/>}/>
                    <Route path="invoices/:id/view" element={<InvoiceView/>}/>
                    <Route path="invoices/:id/pdf" element={<InvoiceViewPdf/>}/>
                    <Route path="invoices/bulk" element={<AddBulkInvoiceForm/>}/>
                    <Route path="expenses" element={<Expenses/>}/>
                    <Route path="revenue" element={<Revenue/>}/>
                    <Route path="products" element={<Products/>}/>
                    <Route path="imports" element={<Imports/>}/>
                    <Route path="locations" element={<Locations/>}/>
                </Route>
            </Routes>
        </AuthProvider>
    </Router>);
};

export default App;
