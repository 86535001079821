import React from 'react';
import AddCustomerForm from './AddCustomerForm';

const EditCustomerForm = () => {
    return (
        <AddCustomerForm isEditMode={true} />
    );
};

export default EditCustomerForm;
