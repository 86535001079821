import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    CircularProgress,
    Snackbar,
    useMediaQuery,
    Select,
    MenuItem,
    Chip, InputLabel, FormControl, Alert
} from '@mui/material';
import { changeInvoiceStatus } from '../services/invoiceService';
import { lighten } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const possibleStatuses = [
    { status: 'pending', color: '#FFEB3B', text: 'Chờ xử lý' },
    { status: 'delivered', color: '#4CAF50', text: 'Đã Giao' },
    { status: 'preorder', color: '#2196F3', text: 'Đặt trước' },
    { status: 'paid_undelivered', color: '#9C27B0', text: 'Đã thanh toán và chưa giao' },
    { status: 'paid', color: '#8BC34A', text: 'Đã hoàn thành' },
];

const InvoiceStatus = ({ invoice }) => {
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState(invoice);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleStatusClick = () => {
        if (isSmallScreen) {
            setOpenStatusModal(true);
        }
    };

    const handleStatusChange = async (newStatus) => {
        setLoading(true);
        try {
            await changeInvoiceStatus(invoiceData.uuid, newStatus);
            setSnackbarOpen(true);
        } catch (error) {
            console.error(error);
        } finally {
            setInvoiceData({
                ...invoiceData,
                status: newStatus,
                statusName: possibleStatuses.find(statusObj => statusObj.status === newStatus).text
            });
            setLoading(false);
            setOpenStatusModal(false);
        }
    };

    return (
        <>
            {isSmallScreen ? (
                <Chip
                    label={loading ? <CircularProgress size={20} /> : invoiceData.statusName}
                    onClick={handleStatusClick}
                    style={{
                        backgroundColor: lighten(possibleStatuses.find(statusObj => statusObj.status === invoiceData.status).color, 0.6),
                        color: theme.palette.getContrastText(lighten(possibleStatuses.find(statusObj => statusObj.status === invoiceData.status).color, 0.6))
                    }}
                    clickable
                />
            ) : (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    {loading ? <CircularProgress size={20} /> :
                    <Select
                        value={invoiceData.status}
                        onChange={(e) => handleStatusChange(e.target.value)}
                        displayEmpty
                        sx={{
                            backgroundColor: lighten(possibleStatuses.find(statusObj => statusObj.status === invoiceData.status).color, 0.6),
                            color: theme.palette.getContrastText(lighten(possibleStatuses.find(statusObj => statusObj.status === invoiceData.status).color, 0.6))
                        }}
                     >
                     {possibleStatuses.map((statusObj) => (
                        <MenuItem key={statusObj.status} value={statusObj.status}>
                            {statusObj.text}
                        </MenuItem>
                    ))}
                    </Select>
                    }
                </FormControl>
            )}

            {isSmallScreen && (
                <Dialog
                    open={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    fullWidth
                    maxWidth="md"
                    sx={{ '& .MuiDialog-paper': { width: '80%' } }}
                >
                    <DialogTitle>Đổi Trạng Thái</DialogTitle>
                    <DialogContent>
                        {loading ? <CircularProgress sx={{ display: 'block', margin: '0 auto' }} /> :
                            <List>
                                {possibleStatuses.map((statusObj) => (
                                    <ListItem key={statusObj.status}>
                                        <ListItemButton
                                            onClick={() => handleStatusChange(statusObj.status)}
                                            sx={{
                                                backgroundColor: lighten(statusObj.color, 0.6),
                                                color: theme.palette.getContrastText(lighten(statusObj.color, 0.6)),
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <ListItemText primary={statusObj.text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenStatusModal(false)} color="primary">
                            Hủy bỏ
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Trạng thái đã được cập nhật</Alert>
            </Snackbar>
        </>
    );
};

export default InvoiceStatus;
