import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import InvoiceStatus from './InvoiceStatus';
import axios from 'axios';
import dayjs from "dayjs";
import CustomLink from "./CustomLink";

const InvoiceCard = ({ invoice }) => {
  const navigate = useNavigate();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    navigate(`/app/invoices/${invoice.uuid}`);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`/api/invoices/${invoice.uuid}`);
      setLoading(false);
      setOpenDeleteConfirm(false);
      alert('Invoice deleted successfully');
    } catch (error) {
      setLoading(false);
      console.error('Error deleting invoice:', error);
      alert('Failed to delete invoice');
    }
  };

  const handleConfirmDelete = () => {
    setOpenDeleteConfirm(true);
  };
  return (
    <Card sx={{ marginBottom: 2, padding: 2 }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold">
           <CustomLink href={`/app/customers/${invoice.customer.uuid}/view`}>
            {invoice.customer.name}
           </CustomLink>
        </Typography>

        {/* Divider Line */}
        <Divider sx={{ marginY: 1 }} />
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 1 }}>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
            Địa chỉ: {invoice.customer.address}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
            Nơi bán: {invoice.communityName}
          </Typography>
        </Stack>
        {/* Invoice Number and Date on the Same Line */}
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 1 }}>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
            Hóa đơn #
            <CustomLink href={`/app/invoices/${invoice.uuid}/view`}>
              {invoice.invoiceNumber}
            </CustomLink>
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
            Ngày: {dayjs(invoice.invoiceDate).format('DD-MM-YYYY')}
          </Typography>
        </Stack>
        <Typography variant="h5" color="primary" sx={{ marginTop: 1 }}>
          {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(invoice.totalAmount)}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{fontSize: 12, paddingTop: 2}}>
            <div dangerouslySetInnerHTML={{__html: invoice.note}}/>
        </Typography>
        {/* Status and Actions Row */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: 2 }}>
          <InvoiceStatus invoice={invoice} />

          {/* Actions on the right */}
          <Box>
            <IconButton onClick={handleEdit} color="primary">
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleConfirmDelete} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Stack>
      </CardContent>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <DialogTitle>Xác Nhận xóa</DialogTitle>
        <DialogContent>
          <Typography>
            Bạn có chắn muốn xóa hóa đơn này không?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
            Hủy bỏ
          </Button>
          <Button onClick={handleDelete} color="error" disabled={loading}>
            {loading ? 'Đang xóa...' : 'Xóa'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default InvoiceCard;
