import React, { useState, useEffect } from 'react';
import {getDashboard} from "../services/DashboardServer";
import { Grid, Typography, Paper, Select, MenuItem, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('month');
  const [labels, setLabels] = useState([]);
  const [invoiceData, setInvoiceData] = useState({ data: []});
  const [customerData, setCustomerData] = useState({ data: []});
  const [financialData, setFinancialData] = useState({ cost: [], revenue: [], profit: [] });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getDashboard(timeRange);
        const newLabels = Object.keys(response.data);
  
        // Setting new state with fresh data based on timeRange
        setLabels(newLabels);
        setInvoiceData({
          labels: newLabels,
          data: newLabels.map(label => response.data[label].numberOfInvoice),
        });
        setCustomerData({
          labels: newLabels,
          data: newLabels.map(label => response.data[label].numberOfNewCustomer),
        });
        setFinancialData({
          labels: newLabels,
          revenue: newLabels.map(label => response.data[label].revenue),
          profit: newLabels.map(label => response.data[label].profit),
        });
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    console.log(invoiceData);
  }, [timeRange]);

  const createChartData = (labels, datasets) => ({
    labels,
    datasets,
  });

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };
  if (isLoading) {
    return <CircularProgress />;
  }

  const renderTableData = (data) => {
    return labels.map((label, index) => (
      <TableRow key={index}>
        <TableCell>{label}</TableCell>
        <TableCell>{data.invoiceData.data[index] ?? '-'}</TableCell>
        <TableCell>{data.customerData.data[index] ?? '-'}</TableCell>
        <TableCell>{data.financialData.revenue[index] ?? '-'}</TableCell>
        <TableCell>{data.financialData.profit[index] ?? '-'}</TableCell>
      </TableRow>
    )).reverse();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Dashboard</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
          <InputLabel id="time-range-label">Time Range</InputLabel>
          <Select
            labelId="time-range-label"
            value={timeRange}
            onChange={handleTimeRangeChange}
            label="Time Range"
          >
            <MenuItem value="dai">Daily</MenuItem>
            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6">Invoices and New Customers</Typography>
          <Line
            data={createChartData(labels, [
              {
                label: `Invoices per ${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)}`,
                data: invoiceData.data,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                tension: 0.1,
              },
              {
                label: `Customers per ${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)}`,
                data: customerData.data,
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                tension: 0.1,
              },
            ])}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6">Revenue and Profit</Typography>
          <Line
            data={createChartData(labels, [
              {
                label: `Revenue per ${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)}`,
                data: financialData.revenue,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                tension: 0.1,
              },
              {
                label: `Profit per ${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)}`,
                data: financialData.profit,
                borderColor: 'rgba(255, 159, 64, 1)',
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                tension: 0.1,
              },
            ])}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6">Data Overview</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Time Period</TableCell>
                  <TableCell>Invoices</TableCell>
                  <TableCell>New Customers</TableCell>
                  <TableCell>Revenue</TableCell>
                  <TableCell>Profit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableData({
                  invoiceData,
                  customerData,
                  financialData: {
                    ...financialData,
                    revenue: financialData.revenue.map(value => value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })),
                    profit: financialData.profit.map(value => value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })),
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
