import axios from 'axios';
import {API_URLS} from "../constants";

// Create an instance of axios
const api = axios.create({
    baseURL: API_URLS.BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Optional: Add a request interceptor for token authorization
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const userLogin = (credentials) => {
    return api.post('/login', credentials);
};

export const userRegister = (userData) => {
    return api.post('/register', userData);
};
