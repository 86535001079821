import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography, Checkbox, FormControlLabel, Alert } from '@mui/material';

const Login = () => {
    const { login, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ email: '', password: '', remember: false });
    const [error, setError] = useState(null);

    useEffect(() => {
        // Redirect if already authenticated
        if (isAuthenticated) {
            navigate('/app/dashboard');
        }
    }, [isAuthenticated, navigate]);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setCredentials((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);  // Reset error before attempting login
        login(credentials, () => navigate('/app/dashboard'), setError); // Redirect to /app on success
    };

    return (
        <Container maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
                <Typography variant="h4" gutterBottom>Login</Typography>
                <Box component="form" onSubmit={handleSubmit} width="100%">
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Password"
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        required
                    />
                    <FormControlLabel
                        control={<Checkbox name="remember" onChange={handleChange} />}
                        label="Remember Password"
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        Login
                    </Button>
                    <Button
                        component={Link}
                        to="/signup"
                        color="secondary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Don't have an account? Sign Up
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
