import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, Typography, Button, AppBar, Toolbar } from '@mui/material';

const LandingPage = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/app/dashboard'); // Redirect to /app if already authenticated
        }
    }, [isAuthenticated, navigate]);

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        MyApp
                    </Typography>
                    <Button color="inherit" component={Link} to="/login">Login</Button>
                    <Button color="inherit" component={Link} to="/signup">Sign Up</Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="md">
                <Box my={5}>
                    <Typography variant="h3" gutterBottom>
                        Welcome to MyApp
                    </Typography>
                    <Typography variant="body1" paragraph>
                        This is the landing page of MyApp. Here we introduce the main features and encourage users to sign up or log in.
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to="/signup">
                        Get Started
                    </Button>
                </Box>
            </Container>
            <Box component="footer" py={3} bgcolor="grey.200" mt={5}>
                <Container maxWidth="md">
                    <Typography variant="body2" align="center">
                        &copy; 2023 MyApp. All rights reserved.
                    </Typography>
                </Container>
            </Box>
        </div>
    );
};

export default LandingPage;
