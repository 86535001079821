import React, { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { 
    Box, 
    CssBaseline, 
    Drawer, 
    List, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    Typography, 
    Divider, 
    IconButton, 
    Menu, 
    MenuItem, 
    useMediaQuery 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Speed';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StoreIcon from '@mui/icons-material/Store';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PublicIcon from '@mui/icons-material/Public';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './Menu.module.css';

const drawerWidth = 240;
const miniDrawerWidth = 60;

const HomePage = () => {
    const { user, logout } = useContext(AuthContext);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Detects small screens
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerExpandToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        logout();
    };

    const handleMenuItemClick = () => {
        if (isSmallScreen) {
            setMobileOpen(false); // Close the drawer on small screens when a menu item is clicked
        }
    };

    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon sx={{ color: '#FF6600' }} />, link: '/app/dashboard' },
        { text: 'Khách hàng', icon: <GroupIcon sx={{ color: '#FF6600' }} />, link: '/app/customers' },
        { text: 'Đơn hàng', icon: <ReceiptIcon sx={{ color: '#FF6600' }} />, link: '/app/invoices' },
        { text: 'Chi Phí', icon: <AttachMoneyIcon sx={{ color: '#FF6600' }} />, link: '/app/expenses' },
        { text: 'Doanh Thu', icon: <MonetizationOnIcon sx={{ color: '#FF6600' }} />, link: '/app/revenue' },
        { text: 'Sản Phẩm', icon: <StoreIcon sx={{ color: '#FF6600' }} />, link: '/app/products' },
        { text: 'Nhập Hàng', icon: <ImportExportIcon sx={{ color: '#FF6600' }} />, link: '/app/imports' },
        { text: 'Nơi Bán', icon: <PublicIcon sx={{ color: '#FF6600' }} />, link: '/app/locations' },
    ];

    const drawerContent = (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Box p={2} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#FF6600',
                }}>
                    <Typography variant="h6" color="primary"
                        sx={{ 
                            fontWeight: 'bold', 
                            color: '#FF6600',
                            height: 24,
                        }}
                    >
                        {drawerOpen && user && user.currentStore.name}
                    </Typography>
                </Box>
                <Divider />
                <List>
                    {menuItems.map((item, index) => (
                        <ListItemButton
                            key={index}
                            component={Link}
                            to={item.link}
                            onClick={handleMenuItemClick}
                            className={styles.sidebarMenuItem}
                            sx={{
                                color: location.pathname.startsWith(item.link) ? '#FF6600' : 'white', // Different color for active item
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 165, 0, 0.1)', // Lighter yellow when hovering
                                },
                                backgroundColor: location.pathname.startsWith(item.link) ? 'rgba(255, 165, 0, 0.2)' : 'inherit', // Background for active item
                                justifyContent: drawerOpen ? 'initial' : 'center',
                                px: drawerOpen ? 2 : 1,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: drawerOpen ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            {drawerOpen && <ListItemText primary={item.text} />} 
                        </ListItemButton>
                    ))}
                </List>
            </Box>
            <Divider />
            {user && (
                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {drawerOpen && (<Typography variant="body1">
                        {user.email}
                    </Typography>)}
                    <IconButton onClick={handleMenuClick}>
                        <AccountCircleIcon sx={{ color: '#FF6600' }} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Box>
            )}
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* Toggle button for mobile */}
            {isSmallScreen && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ 
                        position: 'fixed', 
                        top: 16,
                        left: 16,
                        zIndex: 1300,
                        color: mobileOpen ? '#FF6600' : '#ccc',
                     }}
                >
                    {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
            )}
            {/* Toggle button for large screens */}
            {!isSmallScreen && (
                <IconButton
                    color="inherit"
                    aria-label="toggle drawer"
                    edge="start"
                    onClick={handleDrawerExpandToggle}
                    sx={{ 
                        position: 'fixed', 
                        top: 16,
                        left: drawerOpen ? drawerWidth - 32 : miniDrawerWidth - 32,
                        zIndex: 1300,
                        color: '#FF6600',
                     }}
                >
                    {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            )}
            <Drawer
                sx={{
                    width: drawerOpen ? drawerWidth : miniDrawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerOpen ? drawerWidth : miniDrawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#1e1e1e',
                        fontWeight: 'bolder',
                        color: '#fff',
                    },
                }}
                variant={isSmallScreen ? "temporary" : "permanent"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawerContent}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: '100%', overflowX: 'auto' }}>
                <Outlet /> {/* Renders the nested route components */}
            </Box>
        </Box>
    );
};

export default HomePage;
