import React, { useState } from 'react';
import {
  Button,
  TextField,
  Container,
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Alert,
  Stack,
  CircularProgress,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { bulkInvoices } from '../../services/invoiceService';
import {Helmet} from "react-helmet";

const AddBulkInvoiceForm = () => {
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (!invoiceData) {
      setIsSubmitting(false);
      setErrorMessage('Vui lòng nhập hoá đơn.');
      return;
    }
    bulkInvoices({ invoices: invoiceData })
      .then(() => {
        setIsSubmitting(false);
        navigate('/app/invoices');
      })
      .catch((error) => {
        setIsSubmitting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <Container>
      <Helmet>
        <title>
            Thêm Hoá Đơn Hàng Loạt - Ứng dụng quản lý hoá đơn
        </title>
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
          <Link color="inherit" href="/">
            <HomeIcon sx={{ mr: 0.5 }} />
          </Link>
          <Typography color="text.primary">Thêm Hoá Đơn Hàng Loạt</Typography>
        </Breadcrumbs>
      </Box>
      <Stack spacing={2} marginTop={2}>
        {errorMessage && (
          <Alert
            severity="error"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setErrorMessage('')}
              >
                Close
              </Button>
            }
          >
            {errorMessage}
          </Alert>
        )}
        <Typography variant="h5">Nhập hoá đơn hàng loạt</Typography>
        <TextField
          label="Nhập hàng loạt nhiều hoá đơn"
          multiline
          rows={16}
          value={invoiceData}
          fullWidth
          onChange={(e) => setInvoiceData(e.target.value)}
        />
        <Typography variant="caption">
          Mỗi hàng là một hoá đơn. Khách hàng, sản phẩm và giá cần cập nhật sau đó.
        </Typography>
        <Stack direction="row" spacing={2} marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? 'Đang lưu...' : 'Lưu'}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AddBulkInvoiceForm;
