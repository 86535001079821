import axios from 'axios';
import {API_URLS} from "../constants";

// Create an instance of axios
const api = axios.create({
    baseURL: API_URLS.BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Get refresh token from storage
                const refreshToken = localStorage.getItem('refresh_token');

                // Call the API to get a new access token
                const response = await axios.post(API_URLS.BASE_URL + '/token/refresh', {
                    refresh_token: refreshToken,
                });

                const newAccessToken = response.data.token;

                // Save the new access token
                localStorage.setItem('token', newAccessToken);
                localStorage.setItem('refresh_token', response.data.refresh_token);

                // Update the Authorization header
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry the original request
                return api(originalRequest);
            } catch (err) {
                // Handle refresh token expiration (e.g., redirect to login)
                console.error('Refresh token expired, redirecting to login...');
                window.location.href = '/login';
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);

export default api;