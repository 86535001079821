import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, CircularProgress,
    Container
} from '@mui/material';
import {useParams} from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import CustomLink from "../../components/CustomLink";
import {Helmet} from "react-helmet";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font, BlobProvider } from '@react-pdf/renderer';
import {getSingleInvoice} from "../../services/invoiceService";
import {AuthContext} from "../../contexts/AuthContext";

Font.register({
    family: 'Roboto',
    fonts: [
        { src: '/fonts/Roboto-Regular.ttf' },
        { src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold' }
    ]
});

const base64QRCode = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12,
        fontFamily: 'Roboto',
    },
    header: {
        textAlign: 'center',
        marginBottom: 20,
    },
    section: {
        marginBottom: 10,
    },
    sectionRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    sectionColumn: {
        width: '48%',
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5,
    },
    tableColMerged: {
        width: '80%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5,
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
    },
    qrCode: {
        width: 160,
        height: 160,
        alignSelf: 'left',
        marginTop: 10,
        marginRight: 100,
    },
    boldText: {
        fontWeight: "bold"
    },
    infoAndQRCodeRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoColumn: {
        width: '48%',
    },
    watermark: {
        position: 'absolute',
        top: '30%',
        left: '30%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        fontSize: 50,
        color: 'rgba(0, 0, 0, 0.05)',
        textAlign: 'center',
        fontWeight: 'bold',
    },
});
const InvoiceDocument = ({invoiceData, user}) => {
    return (
        <Document>
            <Page style={styles.page}>
                <Text style={styles.watermark}>shop.ngon.info</Text>
                <View style={styles.header}>
                    <Text style={{ fontSize: 35, fontWeight: "bold" }}>Hóa đơn</Text>
                    <Text style={{ fontSize: 18, fontWeight: "bold" }}>Cửa hàng: {user.currentStore.name}</Text>
                    <Text>Ngày: {new Date(invoiceData.invoiceDate).toLocaleDateString('vi-VN')}</Text>
                </View>

                <View style={styles.sectionRow}>
                    <View style={styles.sectionColumn}>
                        <Text>Khách hàng: <Text style={styles.boldText}>{invoiceData.customer.name}</Text></Text>
                        <Text>Địa chỉ khách hàng: <Text style={styles.boldText}>{invoiceData.customer.address}</Text></Text>
                        <Text>Số điện thoại: <Text style={styles.boldText}>{invoiceData.customer.phone}</Text></Text>
                    </View>
                    <View style={styles.sectionColumn}>
                        <Text>Số hóa đơn: <Text style={styles.boldText}>{invoiceData.invoiceNumber}</Text></Text>
                        <Text>Địa chỉ cửa hàng: <Text style={styles.boldText}>{user.currentStore.address}</Text></Text>
                        <Text>Số điện thoại: <Text style={styles.boldText}>{user.currentStore.phone}</Text></Text>
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={{
                            ...styles.tableCol,
                            width: '40%'
                        }}><Text style={styles.boldText}>Tên sản phẩm</Text></View>
                        <View style={{
                            ...styles.tableCol,
                            width: '10%'
                        }}><Text style={styles.boldText}>Số lượng</Text></View>
                        <View style={{
                            ...styles.tableCol,
                            width: '10%'
                        }}><Text style={styles.boldText}>Đơn vị tính</Text></View>
                        <View style={styles.tableCol}><Text style={styles.boldText}>Đơn Giá</Text></View>
                        <View style={styles.tableCol}><Text style={styles.boldText}>Tổng Giá</Text></View>
                    </View>
                    {invoiceData.invoicesItems && invoiceData.invoicesItems.map((invoicesItem, index) => (
                        <View style={styles.tableRow}>
                            <View style={{
                                ...styles.tableCol,
                                width: '40%'
                            }}><Text style={styles.tableCell}>{invoicesItem.itemName}</Text></View>
                            <View style={{
                                ...styles.tableCol,
                                width: '10%'
                            }}><Text style={styles.tableCell}>{invoicesItem.quantity}</Text></View>
                            <View style={{
                                ...styles.tableCol,
                                width: '10%'
                            }}><Text style={styles.tableCell}>{invoicesItem.unitName}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>
                                {new Intl.NumberFormat('vi-VN', {
                                    style: 'currency',
                                    currency: 'VND'
                                }).format(invoicesItem.originalPrice)}
                            </Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>
                                {new Intl.NumberFormat('vi-VN', {
                                    style: 'currency',
                                    currency: 'VND'
                                }).format(invoicesItem.finalPrice)}
                            </Text></View>
                        </View>
                    ))}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColMerged}><Text style={styles.boldText}>Giảm giá:</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>
                            {new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                            }).format(invoiceData.discount)}
                        </Text></View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColMerged}><Text style={styles.boldText}>Phí giao hàng</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>
                            {new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                            }).format(invoiceData.shippingFee)}
                        </Text></View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColMerged}><Text style={{ ...styles.boldText, fontSize: 16 }}>Tổng thanh toán</Text></View>
                        <View style={styles.tableCol}><Text style={{
                            ...styles.tableCell,
                            fontSize: 16,
                            fontWeight: 'bold'
                        }}>
                            {new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                            }).format(invoiceData.totalAmount)}
                        </Text></View>
                    </View>
                </View>

                <View style={{
                    paddingTop: 30,
                }}>
                    <Text style={{fontSize: 16, fontWeight: 'bold' }}>Thông tin chuyển khoản</Text>
                </View>

                <View style={styles.infoAndQRCodeRow}>
                    <View style={styles.infoColumn}>
                        <Text>Tên Ngân Hàng: <Text style={styles.boldText}>ACB</Text></Text>
                        <Text>Chủ Tài Khoản: <Text style={styles.boldText}>Huynh Thi Phi Yen</Text></Text>
                        <Text>Số Tài Khoản: <Text style={styles.boldText}>22122517</Text></Text>
                    </View>
                    <Image
                        style={styles.qrCode}
                        src={base64QRCode}
                    />
                </View>
            </Page>
        </Document>
    )
}
const InvoiceView = () => {
    const {id} = useParams();
    const { user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [invoiceData, setInvoiceData] = useState({});
    useEffect(() => {
        setIsLoading(true);
        getSingleInvoice(id)
            .then(response => {
                setInvoiceData(response.data);
            })
            .catch(error => {
                navigate('/app/invoices');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }
    return (
        <Container>
            <Helmet>
                 Invoices | Invoices App
            </Helmet>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <CustomLink to="/app/invoices" color="inherit" underline="none">
                    <Box display="flex" alignItems="center">
                        <HomeIcon/>
                    </Box>
                </CustomLink>
            </Box>
            <BlobProvider document={<InvoiceDocument invoiceData={invoiceData} user={user} />}>
                {({ blob, url, loading, error }) => {
                    if (loading) {
                        return 'Đang tải hóa đơn...';
                    }

                    if (error) {
                        return `Lỗi: ${error.message}`;
                    }

                    return (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            Xem file PDF
                        </a>
                    );
                }}
            </BlobProvider>
            <PDFViewer style={{ width: '100%', height: '600px' }}>
                <InvoiceDocument invoiceData={invoiceData} user={user}/>
            </PDFViewer>

        </Container>
    );
};
export default InvoiceView;
